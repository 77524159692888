import {
  type ResponseCreateInput,
  type ResponseCreateOutput,
  type ResponseDetailOutput,
  type ResponseListOutputItem,
  type ResponsesListParams,
  type ResponseUpdateInput,
  type ResponseUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import {
  type CreateParamsWithTenantId,
  type DeleteParamsWithTenantId,
  type DetailParamsWithTenantId,
  injectQueryParams,
  type UpdateParamsWithTenantId,
} from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'responses';

const responseEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getResponseList: build.query<ResponseListOutputItem[], ResponsesListParams>({
      query: ({ tid, ...restParams }) => injectQueryParams(`/tid/${tid}/${RESOURCE}/`, restParams),
      providesTags: [
        TagType.Responses,
        TagType.UserProfile,
        TagType.AssessmentControlPointEvidence,
      ],
    }),

    getResponseDetail: build.query<ResponseDetailOutput, DetailParamsWithTenantId>({
      query: ({ id, tid }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: (result, _error, _params) => [{ type: TagType.Responses, id: result?.uuid }],
    }),

    createResponse: build.mutation<
      ResponseCreateOutput,
      CreateParamsWithTenantId<ResponseCreateInput>
    >({
      query: ({ tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [TagType.Responses, TagType.AssessmentControlPointAnswerTypeFilter],
    }),

    updateResponse: build.mutation<
      ResponseUpdateOutput,
      UpdateParamsWithTenantId<ResponseUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => [TagType.Responses, TagType.AssessmentControlPointAnswerTypeFilter],
    }),

    deleteResponse: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: [TagType.Responses, TagType.AssessmentControlPointAnswerTypeFilter],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateResponseMutation,
  useDeleteResponseMutation,
  useGetResponseDetailQuery,
  useGetResponseListQuery,
  useUpdateResponseMutation,
} = responseEndpoints;
