import { createSelectorV8 } from "../../../utils/createSelector.js";
import { GridEditModes } from "../../../models/gridEditRowModel.js";

/**
 * Select the row editing state.
 */
export const gridEditRowsStateSelector = state => state.editRows;
export const gridRowIsEditingSelector = createSelectorV8(gridEditRowsStateSelector, (editRows, {
  rowId,
  editMode
}) => editMode === GridEditModes.Row && Boolean(editRows[rowId]));
export const gridEditCellStateSelector = createSelectorV8(gridEditRowsStateSelector, (editRows, {
  rowId,
  field
}) => editRows[rowId]?.[field] ?? null);