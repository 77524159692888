import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'leaf';

const leafEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLeafUserProfile: build.query<any, string>({
      query: (tid) => `/tid/${tid}/${RESOURCE}/user-profile/`,
      providesTags: [TagType.LeafUserProfile],
    }),
    getLeafMemberships: build.query<any, string>({
      query: (tid) => `/tid/${tid}/${RESOURCE}/memberships/`,
      providesTags: [TagType.LeafMemberships],
    }),
  }),
  overrideExisting: false,
});

export const { useGetLeafUserProfileQuery, useGetLeafMembershipsQuery } = leafEndpoints;
