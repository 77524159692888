/* eslint-disable @typescript-eslint/no-shadow */
import { createTheme, type Theme } from '@mui/material/styles';
import {} from '@mui/x-data-grid-pro/themeAugmentation';

export const appColors = {
  alert: '#013654',
  black4: '#0000000A',
  black8: '#00000014',
  black12: '#0000001F',
  black26: '#00000042',
  black38: '#00000061',
  black54: '#0000008A',
  black60: '#00000099',
  black87: '#000000DE',
  info: '#0288D1',
  info12: '#0288D11F',
  blue: '#1976D2',
  border: '#CCCCCC',
  chain: '#1B365D',
  containerBg: '#FAFAFA',
  customPaper: '#F3F6F859',
  error: '#D32F2F',
  error12: '#D32F2F1F',
  error20: '#D32F2F33',
  errorShades60: '#541313',
  farm: '#00796B',
  greenProgress: '#004D40',
  greyBorder: '#E0E0E0',
  leaf: '#367C2A',
  lightPurple: '#9C27B0',
  lightPurple4: '#9C27B00A',
  purple: '#512DA8',
  purple50: '#512DA880',
  smk: '#19679C',
  success: '#2E7D32',
  success12: '#2E7D321F',
  warning: '#ED6C02',
  white: '#FFFFFF',
};

const dialogMarginX = 8;
const getMuiDialogPadding = (thm: Theme) => thm.spacing(3);

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  zIndex: {
    drawer: 1000, // AppBar is 1100
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme: thm }) => ({
          backgroundColor: thm.palette.background.paper,
          color: thm.palette.text.primary,
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        main: {
          'div:not([class]):has([style])': {
            zIndex: '0',
          },
        },
        root: {
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell': {
            maxHeight: 'none !important',
            height: 'auto',
            whiteSpace: 'normal',
            wordBreak: 'break-all !important',
          },
        },
        row: {
          maxHeight: 'none !important',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme: thm }) => ({
          padding: getMuiDialogPadding(thm),
        }),
        paperFullWidth: {
          margin: dialogMarginX,
          width: `calc(100% - ${dialogMarginX * 2}px)`,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme: thm }) => ({
          fontSize: 24, // Same as H2
          fontWeight: 400,
          lineHeight: '32.02px',
          letterSpacing: 0.25,
          padding: 0,
          paddingBottom: thm.spacing(3),
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: {
          color: appColors.error,
          '&$error': {
            color: appColors.error,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: appColors.error,
          '&$error': {
            color: appColors.error,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme: thm }) => ({
          padding: 0,
          marginBottom: thm.spacing(2),
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: appColors.white,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: appColors.blue,
          textDecorationColor: appColors.blue,
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 3px 0px #ECEFF1',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderStyle: 'dotted',
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: appColors.farm,
      contrastText: appColors.white,
    },
    secondary: {
      main: appColors.black54,
      contrastText: appColors.white,
    },
    success: {
      main: appColors.success,
    },
    warning: {
      main: appColors.warning,
    },
    text: {
      primary: appColors.black87,
      secondary: appColors.black60,
      disabled: appColors.black38,
    },
    background: {
      default: appColors.containerBg,
    },
    divider: appColors.black12,
    error: {
      main: appColors.error,
    },
    info: {
      main: appColors.info,
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: 0.25,
    },
    h2: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.334,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: 0.15,
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 1,
      lineHeight: 'normal',
      textTransform: 'uppercase',
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.714,
      letterSpacing: 0.4,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.4,
      lineHeight: 1.66,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: 0.15,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: 0.17,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: 0.1,
    },
  },
});

export const smkTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: appColors.smk,
      contrastText: appColors.white,
    },
  },
});

export const leafTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: appColors.leaf,
      contrastText: appColors.white,
    },
  },
  /* typography: {
    fontFamily: [
      '"Open Sans"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: 0.25,
    },
    h2: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.334,
    },
    h3: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: 0.15,
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 1,
      lineHeight: 'normal',
      textTransform: 'uppercase',
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.714,
      letterSpacing: 0.4,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 0.4,
      lineHeight: 1.66,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: 0.15,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: 0.17,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: 0.1,
    },
  }, */
});

// Responsive overrides
theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('sm')]: {
    fontSize: 32, // 40 → 32 on 599px and narrower windows
  },
};
theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('sm')]: {
    fontSize: 24, // 32 → 24 on 599px and narrower windows
  },
};
