import { createSelector } from "../../../utils/createSelector.js";
export const gridDimensionsSelector = state => state.dimensions;

/**
 * Get the summed width of all the visible columns.
 * @category Visible Columns
 */
export const gridColumnsTotalWidthSelector = createSelector(gridDimensionsSelector, dimensions => dimensions.columnsTotalWidth);
export const gridRowHeightSelector = state => state.dimensions.rowHeight;
export const gridContentHeightSelector = state => state.dimensions.contentSize.height;
export const gridHasScrollXSelector = state => state.dimensions.hasScrollX;
export const gridHasScrollYSelector = state => state.dimensions.hasScrollY;
export const gridHasFillerSelector = state => state.dimensions.columnsTotalWidth < state.dimensions.viewportOuterSize.width;
export const gridHeaderHeightSelector = state => state.dimensions.headerHeight;
export const gridGroupHeaderHeightSelector = state => state.dimensions.groupHeaderHeight;
export const gridHeaderFilterHeightSelector = state => state.dimensions.headerFilterHeight;
export const gridVerticalScrollbarWidthSelector = state => state.dimensions.hasScrollY ? state.dimensions.scrollbarSize : 0;
export const gridHorizontalScrollbarHeightSelector = state => state.dimensions.hasScrollX ? state.dimensions.scrollbarSize : 0;
export const gridHasBottomFillerSelector = state => {
  const height = state.dimensions.hasScrollX ? state.dimensions.scrollbarSize : 0;
  const needsLastRowBorder = state.dimensions.viewportOuterSize.height - state.dimensions.minimumSize.height > 0;
  if (height === 0 && !needsLastRowBorder) {
    return false;
  }
  return true;
};