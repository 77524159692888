import { type Theme } from '@mui/material';
import { createContext, type ReactNode, useCallback, useMemo, useState } from 'react';
import { leafTheme, smkTheme, theme } from 'src/theme';

interface ThemeContextData {
  theme: Theme;
  changeTheme?: (thm: Theme) => void;
}

const ThemeContext = createContext<ThemeContextData>({ theme });

interface ThemeProviderProps {
  children: ReactNode;
}

const CustomThemeProvider = ({ children }: ThemeProviderProps) => {
  const [localTheme, setLocalTheme] = useState(theme);
  const changeTheme = useCallback((thm: Theme) => {
    setLocalTheme(thm);
    switch (thm) {
      case smkTheme:
        document.title = 'On the way to PlanetProof';
        document.querySelector('#dynamic-favicon')?.setAttribute('href', '/assets/smk-favicon.ico');
        document
          .querySelector('#dynamic-favicon-dark')
          ?.setAttribute('href', '/assets/smk-favicon.ico');
        break;
      case leafTheme:
        document.title = 'LEAF Sustainable Farming Review (LSFR)';
        document
          .querySelector('#dynamic-favicon')
          ?.setAttribute('href', '/assets/leaf-favicon.ico');
        document
          .querySelector('#dynamic-favicon-dark')
          ?.setAttribute('href', '/assets/leaf-favicon.ico');
        break;
      default:
        break;
    }
  }, []);
  const memoizedTheme = useMemo(
    () => ({
      changeTheme,
      theme: localTheme,
    }),
    [changeTheme, localTheme],
  );

  return <ThemeContext.Provider value={memoizedTheme}>{children}</ThemeContext.Provider>;
};

export { CustomThemeProvider, ThemeContext };
