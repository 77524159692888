import {
  type AssessmentControlPointInputUpdateInputItem,
  type AssessmentControlPointInputUpdateOutputItem,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type DeleteParamsWithTenantId, type UpdateParamsWithTenantId } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-control-point-inputs';

const assessmentControlPointInputEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    deleteAssessmentControlPointInput: build.mutation<void, DeleteParamsWithTenantId>({
      query: ({ id, tid }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/delete/`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, params) => [
        TagType.AssessmentControlPointInputs,
        TagType.AssessmentControlPointAnswer,
        { type: TagType.AssessmentControlPoint, id: params.parentResourceId },
      ],
    }),

    updateAssessmentControlPointInput: build.mutation<
      AssessmentControlPointInputUpdateOutputItem,
      UpdateParamsWithTenantId<AssessmentControlPointInputUpdateInputItem>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) => [
        { type: TagType.AssessmentControlPoint, id: result?.control_point_id },
        TagType.AssessmentControlPointList,
        TagType.ResponseAnswers,
        TagType.AssessmentControlPointInputs,
        TagType.AssessmentControlPointAnswer,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeleteAssessmentControlPointInputMutation,
  useUpdateAssessmentControlPointInputMutation,
} = assessmentControlPointInputEndpoints;
