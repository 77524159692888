import {
  confirmResetPassword,
  confirmSignIn,
  confirmSignUp,
  fetchAuthSession,
  getCurrentUser,
  type JWT,
  resendSignUpCode,
  resetPassword,
  signIn,
  signInWithRedirect,
  signOut,
  signUp,
  updatePassword,
} from '@aws-amplify/auth';
import { type AuthFlowType } from '@aws-amplify/auth/dist/esm/providers/cognito/types';
import { AuthStatus } from 'src/enums/AuthStatus';
import { commonStrings } from 'src/languages/en-UK';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

export interface CognitoUser {
  authFlowType: AuthFlowType | undefined;
  signInUserSession: {
    accessToken: JWT | undefined;
    idToken?: JWT;
  };
  username: string;
}

export interface UserData {
  userId?: string;
  user: CognitoUser;
  authStatus: AuthStatus;
}

export enum SignUpOriginEnum {
  AGRIPLACE = 'agriplace',
  SMK = 'smk',
  LEAF = 'leaf',
}

const authEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    forgotPassword: build.mutation<{ isSuccess: boolean }, { email: string }>({
      async queryFn({ email }) {
        try {
          await resetPassword({ username: email.toLowerCase() });
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    resetPassword: build.mutation<
      { isSuccess: boolean },
      { email: string; code: string; password: string }
    >({
      async queryFn({ email, code, password }) {
        try {
          await confirmResetPassword({
            username: email.toLowerCase(),
            confirmationCode: code,
            newPassword: password,
          });
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    changePassword: build.mutation<
      { isSuccess: boolean },
      { passwordOld: string; passwordNew: string }
    >({
      async queryFn({ passwordOld, passwordNew }) {
        try {
          await updatePassword({ oldPassword: passwordOld, newPassword: passwordNew });
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    changeTemporaryPassword: build.mutation<
      { isSuccess: boolean },
      { passwordNew: string; fullName: string; language: string }
    >({
      async queryFn({ fullName, language, passwordNew }) {
        try {
          await confirmSignIn({
            challengeResponse: passwordNew,
            options: { userAttributes: { name: fullName, locale: language } },
          });
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    signUp: build.mutation<
      UserData,
      {
        email: string;
        password: string;
        fullName: string;
        language: string;
        accountType: string;
        origin?: string;
      }
    >({
      async queryFn({
        email,
        password,
        fullName,
        language,
        accountType,
        origin = SignUpOriginEnum.AGRIPLACE,
      }) {
        let signUpOrigin = origin;
        if (!Object.values(SignUpOriginEnum).includes(origin as SignUpOriginEnum)) {
          signUpOrigin = SignUpOriginEnum.AGRIPLACE;
        }
        try {
          const { userId } = await signUp({
            password,
            username: email.toLowerCase(),
            options: {
              userAttributes: {
                name: fullName,
                locale: language,
                'custom:source_app': 'farm',
                'custom:origin': signUpOrigin,
                'custom:account_type': accountType,
                // TODOHasan: Talk to Jack for these params should be determined programmatically or not
                'custom:privacyPolicyVer': '1.1',
                'custom:termsOfServiceVer': '1.1',
              },
            },
          });
          const user = {
            username: email,
            authFlowType: undefined,
            signInUserSession: {
              accessToken: undefined,
              idToken: undefined,
            },
          };
          return { data: { user, userId, authStatus: AuthStatus.LoggedOut } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    verifySignUp: build.mutation<{ isSuccess: boolean }, { email: string; code: string }>({
      async queryFn({ email, code }) {
        try {
          await confirmSignUp({ username: email.toLowerCase(), confirmationCode: code });
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    resendSignUpVerification: build.mutation<{ isSuccess: boolean }, { email: string }>({
      async queryFn({ email }) {
        try {
          await resendSignUpCode({ username: email.toLowerCase() });
          return { data: { isSuccess: true } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    signIn: build.mutation<UserData, { email: string; password: string }>({
      async queryFn({ email, password }) {
        try {
          const { nextStep } = await signIn({
            password,
            username: email.toLowerCase(),
          });
          if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
            return {
              error: {
                data: { code: 400, message: commonStrings('checkYourMailToConfirm') },
                status: 'CUSTOM_ERROR',
                error: commonStrings('checkYourMailToConfirm'),
              },
            };
          }
          const { userId, username, signInDetails } = await getCurrentUser();
          const { tokens } = await fetchAuthSession();
          const user = {
            username,
            authFlowType: signInDetails?.authFlowType,
            signInUserSession: {
              idTokens: tokens?.idToken,
              accessToken: tokens?.accessToken,
            },
          };
          if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
            return {
              data: {
                user,
                userId: user.username,
                authStatus: AuthStatus.PasswordRequired,
              },
            };
          }
          return { data: { user, userId, authStatus: AuthStatus.LoggedIn } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    signInWithIdentityProvider: build.mutation<UserData, string>({
      async queryFn(idpName) {
        try {
          await signInWithRedirect({
            provider: { custom: idpName },
          });
          const { userId, username, signInDetails } = await getCurrentUser();
          const { tokens } = await fetchAuthSession();
          const user = {
            username,
            authFlowType: signInDetails?.authFlowType,
            signInUserSession: {
              idTokens: tokens?.idToken,
              accessToken: tokens?.accessToken,
            },
          };
          return { data: { user, userId, authStatus: AuthStatus.LoggedIn } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
    }),

    signOut: build.mutation<UserData, void>({
      // @ts-expect-error Third party issue
      async queryFn() {
        try {
          await signOut();
          return { data: { user: null, userId: null, authStatus: AuthStatus.LoggedOut } };
        } catch (error) {
          const message = error?.message || commonStrings('unknownErrorOccurred');
          return { error: { data: error, status: 'CUSTOM_ERROR', error: message } };
        }
      },
      invalidatesTags: [TagType.Auth],
    }),

    getCurrentAuthenticatedUser: build.query<UserData, null>({
      // @ts-expect-error Third party issue
      async queryFn() {
        try {
          const { userId, username, signInDetails } = await getCurrentUser();
          const { tokens } = await fetchAuthSession();
          const user = {
            username,
            authFlowType: signInDetails?.authFlowType,
            signInUserSession: {
              idTokens: tokens?.idToken,
              accessToken: tokens?.accessToken,
            },
          };
          return { data: { user, userId, authStatus: AuthStatus.LoggedIn } };
        } catch (error) {
          return { data: { user: null, userId: null, authStatus: AuthStatus.LoggedOut } };
        }
      },
      providesTags: [TagType.Auth],
    }),
  }),
  overrideExisting: false,
});

export const {
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useChangeTemporaryPasswordMutation,
  useVerifySignUpMutation,
  useResendSignUpVerificationMutation,
  useSignUpMutation,
  useSignInMutation,
  useSignInWithIdentityProviderMutation,
  useSignOutMutation,
  useGetCurrentAuthenticatedUserQuery,
} = authEndpoints;
