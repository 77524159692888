import {
  type AssessmentJSONExportInput,
  type AssessmentSummaryShareableExportCreateInput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type CreateParamsWithTenantId } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'assessment-exports';

type CreateParamsWithStandardId = {
  tid: string;
  id: string;
  standardId: string;
};

type CreateParamsWithStandardIdAndBody<T> = CreateParamsWithStandardId & {
  body: T;
};

const assessmentExportsEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createAssessmentSummaryExportFile: build.mutation<void, CreateParamsWithStandardId>({
      query: ({ id, tid, standardId }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/${standardId}/summary-export/create/`,
        method: 'PUT',
      }),
      invalidatesTags: [TagType.AssessmentExports],
    }),
    createAssessmentSummaryExportWithEvidenceFile: build.mutation<void, CreateParamsWithStandardId>(
      {
        query: ({ id, tid, standardId }) => ({
          url: `/tid/${tid}/${RESOURCE}/${id}/${standardId}/summary-export-with-evidence/create/`,
          method: 'PUT',
        }),
        invalidatesTags: [TagType.AssessmentExports],
      },
    ),
    createShareableAssessmentSummaryExport: build.mutation<
      void,
      CreateParamsWithStandardIdAndBody<AssessmentSummaryShareableExportCreateInput>
    >({
      query: ({ id, tid, standardId, body }) => ({
        body,
        url: `/tid/${tid}/${RESOURCE}/${id}/${standardId}/shareable-summary-export/create/`,
        method: 'PUT',
      }),
      invalidatesTags: [TagType.AssessmentExports],
    }),
    createJsonExport: build.mutation<void, CreateParamsWithTenantId<AssessmentJSONExportInput>>({
      query: ({ tid, body }) => ({
        body,
        url: `/tid/${tid}/${RESOURCE}/json-export/`,
        method: 'POST',
      }),
      invalidatesTags: [TagType.AssessmentExports],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateAssessmentSummaryExportFileMutation,
  useCreateAssessmentSummaryExportWithEvidenceFileMutation,
  useCreateJsonExportMutation,
  useCreateShareableAssessmentSummaryExportMutation,
} = assessmentExportsEndpoints;
