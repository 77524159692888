import config from './config';

export default {
  mandatorySignIn: true,
  region: config.cognito.REGION,
  userPoolId: config.cognito.USER_POOL_ID,
  userPoolClientId: config.cognito.APP_CLIENT_ID,
  loginWith: {
    oauth: {
      domain: config.cognito.OAUTH_DOMAIN,
      scopes: ['openid'],
      redirectSignIn: [config.cognito.OAUTH_REDIRECT_SIGN_IN],
      redirectSignOut: [config.cognito.OAUTH_REDIRECT_SIGN_OUT],
      responseType: 'code' as 'code' | 'token', // Stupid type defintion in amplify
    },
  },
};
